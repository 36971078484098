import Vue from 'vue';
import App from './App';
import { router } from './router';
import 'vant/lib/index.css';
import store from "@/store";
import i18n from "@/lang";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { PUSHER_APP_CLUSTER, PUSHER_APP_KEY, PUSHER_HOST, PUSHER_PORT, PUSHER_SCHEME } from "@/config";
// import { post } from "@/api/http.js";

window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: PUSHER_APP_KEY,
    cluster: PUSHER_APP_CLUSTER ?? 'mt1',
    wsHost: PUSHER_HOST,
    wsPort: PUSHER_PORT,
    wssPort: PUSHER_PORT,
    forceTLS: (PUSHER_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    // authEndpoint: 'http://tikto/broadcasting/auth',
    // authorizer: (channel) => {
    //     return {
    //         authorize: (socketId, callback) => {
    //             post('/api/broadcasting/auth', {
    //                 socket_id: socketId,
    //                 channel_name: channel.name
    //             }, {
    //                 headers: {
    //                     Authorization: 'Bearer ' + store.state.token
    //                 }
    //             })
    //                 .then(response => {
    //                     callback(null, response.data);
    //                 })
    //                 .catch(error => {
    //                     callback(error);
    //                 });
    //         }
    //     };
    // },
});

import {
    Form,
    Field,
    Button,
    NoticeBar,
    Tab,
    Tabs,
    Popup,
    Stepper,
    Tabbar,
    TabbarItem,
    Icon,
    Cell,
    CellGroup,
    NavBar,
    Toast,
    List,
    CountDown,
    Uploader,
    ActionSheet,
    Dialog,
    Picker,
} from 'vant';

Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(NoticeBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Popup);
Vue.use(Stepper);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(NavBar);
Vue.use(Toast);
Vue.use(List);
Vue.use(CountDown);
Vue.use(Uploader);
Vue.use(ActionSheet);
Vue.use(Dialog);
Vue.use(Picker);

new Vue({
    i18n,
    store,
    router,
    el: '#app',
    render: h => h(App)
});
